import React from 'react';
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Location from '../components/location';


const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: sanityPage(_id: { eq: "a7547bf0-6193-48b2-95a7-cb9deb21a62e" }) {
        id
        title
        _rawContent
      }
      headerImage: file(relativePath: { eq: "homepage_panel_02.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      stacey1: file(relativePath: { eq: "stacey1.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 300, maxHeight:300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      stacey2: file(relativePath: { eq: "stacey2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 300, maxHeight: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      stacey3: file(relativePath: { eq: "stacey3.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 300, maxHeight: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const {page, headerImage, stacey1, stacey2, stacey3} = data;

  return (
    <Layout headerImage={headerImage}>
      <SEO title="About Willow Branch Flowers" />
      <section className="bg-textured-content py-12 border-b border-gray-400">
        <div className="md:flex container mx-auto">
          <div className="md:w-1/3 pr-12 px-2 md:px-0">
            <h1 className="text-3xl font-serif font-bold text-green-800 mb-2">About</h1>

            <Location subPage />

          </div>
          <div className="md:w-2/3 flex flex-wrap leading-loose">
            <div className="w-full md:w-2/3">
              {page._rawContent.map((block, idx) => {
                return (
                  <p key={idx} className="mb-6 px-4">{block.children[0].text}</p>
                )
              })}
            </div>
            <div className="w-full md:w-1/3">
              <div className="mb-4">
                <div className="md:shadow-md mx-2 mb-6"><img src={stacey3.childImageSharp.fluid.src} alt="Stacey Abernathy at Willow Branch Offices"/></div>
                <div className="md:shadow-md mx-2 mb-6"><img src={stacey1.childImageSharp.fluid.src} alt="Stacey Abernathy Outdoor Floral"/></div>
                <div className="md:shadow-md mx-2 mb-6"><img src={stacey2.childImageSharp.fluid.src} alt="Stacey Abernathy Indoor Floral Arrangements"/></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </Layout>
   );
}

export default AboutPage;
